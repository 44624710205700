<template>
  <el-dialog width="70%" :title="!dataForm.id ? '新增配置' : '编辑配置'" :close-on-click-modal="false" :visible.sync="visible">
    <h3>基础信息编辑</h3>
    <el-form :model="dataForm" ref="dataForm" label-width="120px" :rules="dataRule">
      <!-- <el-form-item label="开屏url：" prop="url">
        <el-input v-model="dataForm.url" placeholder="开屏url" class="input-width"></el-input>
      </el-form-item> -->

      <el-form-item label="开屏url：">
        <el-upload class="avatar-uploader" :action="ossUploadUrl" :show-file-list="true" :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload" :limit="1" :on-remove="handleAvatarRemove">
          <img v-if="dataForm.url" :src="dataForm.url" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="停留时间/秒：" prop="duration">
        <el-input v-model="dataForm.duration" placeholder="停留时间/秒" class="input-width"></el-input>
      </el-form-item>

      <el-form-item label="链接描述：" prop="description">
        <el-input v-model="dataForm.description" placeholder="链接描述" class="input-width"></el-input>
      </el-form-item>

      <el-form-item label="语言码：" prop="lang">
        <el-select v-model="dataForm.lang" clearable placeholder="all">
          <el-option v-for="item in langOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="状态：" prop="status">
        <el-select v-model="dataForm.status" clearable placeholder="状态">
          <el-option v-for="item in statusOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="跳转链接：" prop="redirect">
        <el-input v-model="dataForm.redirect" placeholder="跳转链接" class="input-width"></el-input>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="beforeSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { langOpt } from "@/views/common/commonOpt";

const sourceDataForm = {
  id: 0,
  description: '',
  duration: '',
  lang: 'all',
  redirect: '',
  status: 0,
  url: '',
}
export default {
  data() {
    return {
      ossUploadUrl: "",
      visible: false,
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),

      dataRule: {
        'url': [
          { required: true, message: '开屏url不能为空', },
        ],
        'duration': [
          { required: true, message: '停留时间不能为空', }
        ],
        'status': [
          { required: true, message: '状态不能为空', }
        ],
        'lang': [
          { required: true, message: '语种不能为空', }
        ],
      },
      statusOpt: [
        {
          value: 0,
          label: '未开启'
        },
        {
          value: 1,
          label: '已开启'
        }
      ],
      langOpt: langOpt,
    }
  },
  created() {
    this.ossUploadUrl = this.$http.adornUrl(
      `/sys/oss/upload/compress?token=${this.$cookie.get("token")}`
    );
  },
  methods: {
    init(row) {
      console.log('row', row)
      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.dataForm.id = !row ? 0 : row.id

      if (this.dataForm.id === 0) {
        // 新增模式
        this.visible = true
        return;
      }

      if (this.$refs['dataForm']) this.$refs['dataForm'].resetFields()

      this.visible = true
      const { description, duration, lang, redirect, status, url } = row
      this.dataForm.description = description
      this.dataForm.duration = duration
      this.dataForm.lang = lang
      this.dataForm.redirect = redirect
      this.dataForm.status = status
      this.dataForm.url = url
    },

    beforeSubmit() {
      this.$confirm('当前配置信息是否已确认无误?', '提示', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        this.dataFormSubmit();
      })
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 或者png 格式!');
      }
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isJPG && isLt5M;
    },
    handleAvatarSuccess(res, file) {
      if (res && res.code === 0) {
        this.dataForm.url = res.url
      } else {
        this.$message.error('上传图片失败:' + res.msg)
      }
    },
    handleAvatarRemove(res, file) {
      this.dataForm.url = ''
    },
    // 表单提交
    dataFormSubmit() {

      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/admin/splash/${!this.dataForm.id ? 'add' : 'update'}`),
            method: 'post',
            data: this.$http.adornData({
              ...this.dataForm
            })
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        } else {
          this.$message.error('未通过校验！')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
h3 {
  padding: 2vh 0;
  border-top: 1px solid rgba(184, 184, 184, 0.6)
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 12vw;
  height: 12vw;
  line-height: 12vw;
  text-align: center;
}

.avatar {
  width: 12vw;
  height: 12vw;
  display: block;
}

.input-width {
  width: 30vw;
}
</style>