<template>
    <div>
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item label="描述">
                <el-input v-model="dataForm.desc" placeholder="输入描述" clearable size="small" @clear="refresh()"></el-input>
            </el-form-item>

            <el-form-item label="语种">
                <el-select v-model="dataForm.lang" clearable placeholder="all" size="small" @clear="refresh()">
                    <el-option v-for="item in langOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button @click="getDataList()" type="primary">搜索</el-button>
                <el-button @click="refresh()">重置</el-button>
                <el-button @click="addOrUpdateHandle()" type="danger">新增</el-button>
            </el-form-item>
        </el-form>

        <el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
            style="width: 100%;">

            <el-table-column prop="url" header-align="center" align="center" label="开屏url" width="100">
                <template slot-scope="scope">
                    <img style="width: 40px; height: 40px" :src="scope.row.url" />
                </template>
            </el-table-column>
            <el-table-column prop="description" width="150px" header-align="center" align="center" label="链接描述">
            </el-table-column>

            <el-table-column prop="duration" header-align="center" align="center" label="停留时间/秒">
            </el-table-column>
            <el-table-column prop="lang" header-align="center" align="center" label="语言码">
            </el-table-column>
            <el-table-column prop="redirect" width="200px" header-align="center" align="center" label="跳转链接">
            </el-table-column>
            <!-- <el-table-column prop="url" width="200px" header-align="center" align="center" label="开屏url">
            </el-table-column> -->
            <el-table-column prop="status" header-align="center" align="center" label="状态">
                <template slot-scope="scope">
                    <div>
                        <el-tag v-if="scope.row.status === 0" type="danger">未开启</el-tag>
                        <el-tag v-else-if="scope.row.status === 1" type="success">已开启</el-tag>
                    </div>
                </template>
            </el-table-column>

            <el-table-column header-align="center" align="center" width="100" label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row)">配置编辑
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>

        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
</template>
<script>
import AddOrUpdate from "./splash-add-or-update";
import { langOpt } from "@/views/common/commonOpt";

const sourceDataForm = {
    desc: '',
    lang: 'all',
}
export default {
    data() {
        return {
            dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
            pageSize: 10,
            pageIndex: 1,
            totalPage: 0,
            dataList: [],
            dataListLoading: false,
            dataListSelections: [],
            addOrUpdateVisible: true,
            langOpt: langOpt,
        }
    },
    components: {
        AddOrUpdate
    },
    activated() {
        this.getDataList()
    },
    methods: {
        refresh() {
            this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
            this.page = 1
            this.limit = 10
            this.getDataList()
        },
        // 获取数据列表
        getDataList() {
            this.dataListLoading = true
            this.$http({
                url: this.$http.adornUrl('/admin/splash/list'),
                method: 'post',
                data: this.$http.adornData({
                    ...this.dataForm,
                    page: this.pageIndex,
                    limit: this.pageSize,
                })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.dataList = data.result.data
                    this.totalPage = data.result.last_page
                } else {
                    this.$message.error(data.msg)
                }
                this.dataListLoading = false
            })
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getDataList()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getDataList()
        },
        // 多选
        selectionChangeHandle(val) {
            this.dataListSelections = val
        },

        // 新增 / 修改
        addOrUpdateHandle(row) {
            this.addOrUpdateVisible = true
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(row)
            })
        },
    },
}
</script>
<style scoped></style>
